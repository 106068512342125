<template>
  <ws-dialog
      v-model="display"
      @save="saveChanges"
      @delete="deleteEntity(entityData.lang)"
      :title="$t(!displayInfo ? 'homepage.register.business.name.title' : 'avalon.language.add_language')"
      :show-delete="!newEntity && displayDelete"
  >

      <v-sheet v-if="displayInfo"  class="d-flex align-center mb-6 wsRoundedLight">
<!--        <v-icon :color="wsACCENT" class="mr-3">mdi-information-outline</v-icon>-->

        <h4 class="font-weight-regular" :style="`color : ${wsDARKER}`">{{ $t('avalon.language.add_language_names_description') }}</h4>
      </v-sheet>

    <!-- CONTENT-->
    <ws-select
        v-model="entityData.lang"
        :readonly="!newEntity"
        :disabled="!newEntity"
        :items="languagesSelect"
        :label="$t('Language')"
        :placeholder="$t('ChooseLanguage')"
        :error="langError"
        @input="langError = false"
        autocomplete
    />
    <ws-text-field
        v-model="entityData.name"
        :label="`${$t('Name')}`"
        :error="nameError"
        @input="nameError = false"
        width="100%"
        class="mr-6 mt-6"
        clearable
    />
    <ws-text-field
        v-model="entityData.description"
        :label="`${$t('Description')}`"
        area
        width="100%"
        class="mr-6 mt-5"
        clearable
    />

  </ws-dialog>
</template>

<script>
import languagesList from "@/mixins/languagesList";
import {mapActions} from "vuex";

export default {
  name: "AddEditLanguageDialog",
  mixins : [languagesList],
  props : {
    value : {
      type : Boolean
    },
    entityData : {
      type : Object,
      default() { return {} }
    },
    newEntity : {
      type : Boolean,
      default : false
    },
    displayDelete : {
      type : Boolean,
      default : true
    },
    displayInfo : {
      type : Boolean,
      default : false
    },
    languages: {
      type : Array
    }
  },
  data() {
    return {
      display : false,
      langError : false,
      nameError : false,
    }
  },
  computed : {
    languagesSelect() {
      return this.LANGUAGES_SELECT.filter(el => !this.langs.includes(el))
    },
    langs() {
      return this.languages || this.$store.state.business.selectedBusiness.langs
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('settings',  [
      'EDIT_BUSINESS_GENERAL_SETTINGS'
    ]) ,

    async saveChanges(){
      if ( !this.entityData.lang ) {
        this.langError = true
        return this.notify(this.$t('ChooseLanguage') , 'warning')
      }
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('EnterName') , 'warning')
      }
      if ( !this.$store.state.business.selectedBusiness.langs && this.languages ) {
        this.$store.state.business.selectedBusiness.langs = this.COPY(this.languages)
      }

      let data = this.COPY(this.entityData)
      if ( this.newEntity && !this.langs.includes(this.entityData.lang) ) {
        this.$store.state.business.selectedBusiness.langs.push(this.entityData.lang)
        data.langs = this.$store.state.business.selectedBusiness.langs
      }
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS(data)
      if ( !result ) {
        return
      }
      this.$emit('save' , this.entityData)
      this.display = false

    },
    async deleteEntity(lang) {

      if ( !this.$store.state.business.selectedBusiness.langs && this.languages ) {
        this.$store.state.business.selectedBusiness.langs = this.COPY(this.languages)
      }

      if ( this.langs.length < 2 ) {
        return this.notify(this.$t('avalon.language.remove_lang_error') , 'warning')
      }
      let data = {
        langs : this.langs.filter(el => el !== lang)
      }
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS(data)
      if ( !result ) {
        return
      }

      this.$store.state.business.selectedBusiness.langs = this.langs.filter(el => el !== lang)
      this.$emit('delete' , lang)
      this.display = false

    },

  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>